import { OptionItem } from '@/common/types';

type CustomFieldSelectValueType = {
  value: string;
  categoryName?: string | null;
};

export type CustomFieldSelectOptionType = {
  value: string;
  id: number;
  dependencyAssets?: number[];
};

export type CustomFieldSelectOptionCategoryType = {
  name: string;
  selectItems: CustomFieldSelectItemType[];
};

export type CustomFieldSelectItemType = {
  option?: CustomFieldSelectOptionType | null;
  category?: CustomFieldSelectOptionCategoryType | null;
};

export const generateOptionToSelectItem = (
  option: CustomFieldSelectOptionType
): CustomFieldSelectItemType => {
  return {
    option,
  };
};

export const valueWithCategoryName = ({ value, categoryName }: CustomFieldSelectValueType) => {
  return categoryName ? `${categoryName} > ${value}` : value;
};

export const hasAssetDependencyOrNoDependency = (
  option: CustomFieldSelectOptionType,
  assetId: number | undefined
): boolean => {
  return (
    assetId === undefined ||
    option.dependencyAssets === undefined ||
    option.dependencyAssets.length === 0 ||
    option.dependencyAssets.includes(assetId)
  );
};

export const generateItemValueBySelectOption = (item: CustomFieldSelectItemType): OptionItem => {
  const { category, option } = item;
  if (category) {
    return {
      id: `category:${category.name}`,
      label: category.name,
      isSelectDisabled: true,
      children: category.selectItems.map(({ option }) => {
        //　以下のパターンは存在しないので、落とす
        if (!option) throw new Error('Invalid select items(category option)');
        return {
          label: option.value,
          id: option.id,
        };
      }),
    };
  }
  if (option) {
    return {
      label: option.value,
      id: option.id,
    };
  }
  throw new Error('Invalid select items');
};
